import React, {useReducer} from 'react'
import {ProductContext} from "./productsContext";
import {productsReducer} from './productsReducer'
import {GET_STORES, GET_PRODUCTS_BY_STORE, LOADING, SET_PRODUCT} from "../actionTypes";
import axios from "axios";

const ProductsState = ({children}) => {

    const initialState = {
        stores: [],
        products: [],
        product: {},
        loading: true
    }

    const [state, dispatch] = useReducer(productsReducer, initialState)

    const getStores = async () => {

        const response = await axios.get('https://fi-en.openfoodfacts.org/stores.json');
        const stores = response.data.tags;

        dispatch({
            type: GET_STORES,
            payload: stores
        })
    }

    const getProductsbyStore = async store => {
        setLoading()
        const response = await axios.get('https://fi-en.openfoodfacts.org/store/'+ store +'.json');
        const products = response.data.products;


        dispatch({
            type: GET_PRODUCTS_BY_STORE,
            payload: products
        })
    }

    const setLoading = () => {
        dispatch({
            type: LOADING
        })
    }

    const setProduct = product => {
        dispatch({
            type: SET_PRODUCT,
            payload: product
        })
    }

    const {stores, products, loading, product} = state;
    return (
        <ProductContext.Provider value={{getProductsbyStore, getStores, setProduct, stores, products, loading, product}}>
            {children}
        </ProductContext.Provider>
    )
}

export default ProductsState