import React from 'react';
import './App.scss';
import {BrowserRouter as Router, Switch, Route, NavLink} from "react-router-dom";
import Home from "./pages/Home"
import About from "./pages/About"
import Alert from "./components/Alert";
import AlertState from "./context/alert/alertState";
import ProductsState from "./context/products/productsState";
import Store from "./pages/Store";
import {Product} from "./pages/Product";

// ToDO 404 страницу сделать
// ToDO Если продукт пустой и мы на странице продукта, то сделать запрос на главную

function App() {
  return (
    <Router>
      <ProductsState>
        <AlertState>
          <section>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="collapse navbar-collapse" id="navbarNav">
                <div className="container">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <NavLink className="nav-link" activeClassName="active" to="/"
                               exact>Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" activeClassName="active"
                               to="/about">About</NavLink>
                    </li>
                  </ul>
                </div>

              </div>
            </nav>
            <div className="container">
              <Alert/>
              <Switch>
                <Route path="/about">
                  <About/>
                </Route>
                <Route path="/store/:name/:id" component={Product}/>
                <Route path="/store/:name" component={Store}/>

                <Route path="/">
                  <Home/>
                </Route>
              </Switch>
            </div>
            <footer>
              <div className="container">
                <p>Creeated just for porfolio by <a href="https://github.com/Beefeater84" target='_blank'
                                                    rel="noopener noreferrer">https://github.com/Beefeater84</a></p>
              </div>
            </footer>

          </section>
        </AlertState>
      </ProductsState>
    </Router>
  );
}

export default App;

