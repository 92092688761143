import React from "react"

const About = () => {

    return (
        <React.Fragment>
            <h2>About</h2>

            <p>React based Project</p>
            <ul>
                <li>Built with functional components only</li>
                <li>Used flux model (Redux)</li>
                <li>Used async/await request to REST API:  <a href="https://fi.openfoodfacts.org/" target="_blank" rel="noopener noreferrer">https://fi.openfoodfacts.org/</a></li>
                <li>Used dynamic-created urls</li>
            </ul>
        </React.Fragment>
    )
}

export default About