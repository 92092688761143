import React from 'react';
import {Link} from "react-router-dom";


const ProductsList = ({name, products,}) => {
    return (
        <div className="col-4">
            <Link to={'/store/' + name}>
                <div className="card">
                    <div className="card-body">
                        <h3 style={{marginBottom: 0}}>{name} ({products})</h3>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ProductsList


