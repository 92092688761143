import React, {useContext} from 'react';
//import axios from "axios";
import {AlertContext} from "../context/alert/alertContext";


const Search = () => {

    const {show} = useContext(AlertContext);

    // const getProducts = async () => {
    //     try {
    //         const response = await axios.get('https://fi-en.openfoodfacts.org/cgi/search.pl?action=process&tagtype_0=categories&tag_contains_0=contains&tag_0=breakfast_cereals&tagtype_1=nutrition_grades&tag_contains_1=contains&tag_1=A&additives=without&ingredients_from_palm_oil=without&json=true'
    //         );
    //         console.log(response);
    //
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    // const onSearch = event => {
    //
    //     const value = event.target.value;
    //
    //     if (event.key !== 'Enter'){
    //         return
    //     }
    //
    //     if (!!value.trim()){
    //         getProducts()
    //     }else{
    //         console.log("Введите данные")
    //     }
    // }

    const onSubmit = event => {
        const value = event.target.value;

         if (event.key === 'Enter' && !value.trim()){
            show('Введите название продукта')
        }
    }

    return <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Search" onKeyPress={onSubmit}/>
}

export default Search;