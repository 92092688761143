import React from 'react'
import classes from './loading.module.scss'

const Loading = () => {
    return (
        <div className={classes.body}>
            <div className={classes.paper_man_wrapper}>
                <div className={classes.paper_man}>
                    <div className={[classes.paper_man_body, classes.part, classes.div].join(' ')}>
                        <div className={[classes.paper_man_head, classes.part, classes.div].join(' ')}/>
                        <div className={[classes.paper_man_arm, classes.left, classes.div].join(' ')}>
                            <div className={[classes.paper_man_arm_1, classes.part, classes.div].join(' ')}>
                                <div className={[classes.paper_man_arm_2, classes.part, classes.div].join(' ')}>
                                    <div className={[classes.paper_man_arm_hand, classes.part, classes.div].join(' ')}/>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.paper_man_arm, classes.right, classes.div].join(' ')}>
                            <div className={[classes.paper_man_arm_1, classes.part, classes.div].join(' ')}>
                                <div className={[classes.paper_man_arm_2, classes.part, classes.div].join(' ')}>
                                    <div className={[classes.paper_man_arm_hand, classes.part, classes.div].join(' ')}/>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.paper_man_leg, classes.left, classes.div].join(' ')}>
                            <div className={[classes.paper_man_leg_1, classes.part, classes.div].join(' ')}>
                                <div className={[classes.paper_man_leg_2, classes.part, classes.div].join(' ')}>
                                    <div className={[classes.paper_man_leg_foot, classes.part, classes.div].join(' ')}/>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.paper_man_leg, classes.right, classes.div].join(' ')}>
                            <div className={[classes.paper_man_leg_1, classes.part, classes.div].join(' ')}>
                                <div className={[classes.paper_man_leg_2, classes.part, classes.div].join(' ')}>
                                    <div className={[classes.paper_man_leg_foot, classes.part, classes.div].join(' ')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Loading