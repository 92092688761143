import React, {useContext, useEffect} from 'react'
import {ProductContext} from "../context/products/productsContext";
import Loading from "../components/Loading";
import {NavLink} from "react-router-dom";

const Store = ({match}) => {

  const {getProductsbyStore, products, loading, setProduct} = useContext(ProductContext);
  const store = match.params.name;

  useEffect(() => {
    const store = match.params.name;
    getProductsbyStore(store)
    // eslint-disable-next-line
  }, [])


  if (loading) {
    return <Loading/>
  }


  return (
    <React.Fragment>
      <h2>Продукты магазина {store}</h2>
      <div className="row">
        {
          products.map(product => {

            const name = product.product_name || product.product_name_fi || product.product_name_de || product.product_name_en || 'Имя не определено'

            //console.log(product)
            return (
              <React.Fragment key={product.id}>
                <div className="col-4">
                  <h5
                    onClick={() => setProduct(product)}
                  >
                    <NavLink
                      to={`/store/${store}/` + product.id}
                    >
                      {name}
                    </NavLink>
                  </h5>
                </div>
              </React.Fragment>

            )
          })
        }
      </div>
    </React.Fragment>
  )
}

export default Store