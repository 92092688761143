import React, {useContext} from 'react'
import {ProductContext} from "../context/products/productsContext";
import {Redirect} from 'react-router-dom'

export const Product = () => {

    const {product} = useContext(ProductContext)
    const name = product.product_name || product.product_name_fi ||  product.product_name_de || 'No name'

    if (!product.nutriments){
        return (
            <Redirect to="/somewhere/else" />
        )
    }

    return (
        <React.Fragment>
        <h1 style={{marginBottom: '2rem'}}>{name}</h1>
            <div className="row">
                <div className="col-3">
                    <img src={product.image_front_url} alt="" style={{maxWidth: '100%'}}/>
                </div>
                <div className="col-6">
                    <ul>
                        <li>Energy: {product.nutriments.energy_100g || "no data"} ccal</li>
                        <li>Carbohydrates: {product.nutriments.carbohydrates_100g || "no data"} g</li>
                        <li>Protein: { product.nutriments.proteins_100g || "no data"} g</li>
                        <li>Fat: { product.nutriments.fat_100g || "no data"} g</li>
                    </ul>
                </div>
            </div>


        </React.Fragment>

    )
}



