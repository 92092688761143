import {GET_PRODUCTS_BY_STORE, GET_STORES, LOADING, SET_PRODUCT} from "../actionTypes";

export const productsReducer = (state, action) => {
    switch (action.type) {
        case GET_PRODUCTS_BY_STORE:
            return {...state, products: action.payload, loading: false}
        case GET_STORES:
            return {...state, stores: action.payload}
        case LOADING:
            return {...state, loading: true}
        case SET_PRODUCT:
            return{...state, product: action.payload}

        default:
            return state
    }
}