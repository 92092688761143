import React, {useContext} from 'react';
import {AlertContext} from "../context/alert/alertContext";


const Alert = () => {
    const {state, hide} = useContext(AlertContext)


    if (!state) return null;



    return (
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
            <strong>{state.text}</strong>
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={hide}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    )
}

export default Alert