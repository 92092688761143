import React, {useEffect, useContext} from 'react';
import ProductsList from "../components/ProductsList.js";
import Search from "../components/Search";
import {ProductContext} from './../context/products/productsContext.js'


const Home = () => {

    const {getStores, stores} = useContext(ProductContext)

    useEffect(() => {
        getStores()
        // eslint-disable-next-line
    }, [])

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12">
                    <Search/>
                </div>
            </div>
            <div className="row">
                {stores.map(item => {
                    if (item.products > 3) {
                        return (
                            <ProductsList
                                key={item.id}
                                name={item.name}
                                products={item.products}
                            />
                        )
                    }
                })}
            </div>
        </React.Fragment>

    )
}

export default Home

// 'https://fi-en.openfoodfacts.org/',
//     {
//         headers: {
//             Authorization: 'User-Agent: Finland receepts - Browser - Version 1.0'
//         }
//     }